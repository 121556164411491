<template>
  <app-layout v-if="authenticated">
    <div class="m-auto space-y-5 p-20 text-center">
      <app-logo />
      <p class="text-gray-400">
        {{ $t("general.page_not_found") }}
      </p>
    </div>
  </app-layout>
  <app-layout-main v-else>
    <div class="grid place-items-center h-full">
      <div
        class="
          p-20
          mx-auto
          max-w-sm
          shadow-lg
          space-y-5
          rounded-xl
          bg-white
          text-center
        "
      >
        <app-logo />
        <p class="text-gray-400">
          {{ $t("general.page_not_found") }}
        </p>
      </div>
    </div>
  </app-layout-main>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters["authStore/authenticated"];
    },
  },
};
</script>
